<template>
 <div :class="windowClass">
   <v-container>
    <v-row>
      <v-col cols="12">
                <v-card>
               <v-toolbar>
                 <v-icon left color="secondary">mdi-cogs</v-icon> Settings
                 <v-spacer/>
                 
                 <v-divider vertical inset class="ml-5 mr-2"/>
                        <!-- <v-btn text small color="success"  @click="state='new',form=true"><v-icon small left color="success">mdi-plus</v-icon>  Add Game</v-btn> -->
               </v-toolbar>
               <v-card-text>
                <v-data-table
                    class="va-table"
                    dense
                    :headers="headers"
                    :items="games"
                    >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item,index) in items" :key="index">
                          <!-- <template v-if=" item.code =='3D' || item.code =='2D'"> -->
                            <td>
                            <v-icon  x-small left color="warning" @click="game=item, state='update', form=true" >mdi-pencil</v-icon>
                            {{ item.name }}</td>
                            <td>{{ item.code }}</td>
                            <!-- <td>{{ item.type }}</td> -->
                            <td>{{ $money(item.winning_amount) }}</td>
                            <!-- <td>{{item.payout_percent}}%</td> -->
                            <td>{{ $money(item.fund) }}</td>
                            <td>
                              <v-chip small class="def-font ma-1" v-for="(item,index) in item.Draws" :key="index + '-d'" color="info"> {{item}}</v-chip>
                            </td>
                             <td>
                               <v-chip small :color="item.status=='Active'?'success':''"> {{ item.status }}</v-chip>
                              </td>
                          <!-- </template> -->
                        </tr>
                        </tbody>
                    </template>
                    </v-data-table>
               </v-card-text>
             </v-card>
          </v-col>

<!-- 
           <v-col cols="12">
                <v-card>
               <v-toolbar>
                 <v-icon left color="secondary">mdi-numeric</v-icon> Special Combinations
                 <v-spacer/>
                 
      
               </v-toolbar>
               <v-card-text>
                 <v-row>
                    <v-col cols="6">
                          <v-toolbar dense>
                             LAST TWO
                            <v-spacer/>
                            
                            <v-divider vertical inset class="ml-5 mr-2"/>
                            <v-btn fab x-small @click="add_combination('LASTTWO')"><v-icon small color="success">mdi-plus</v-icon></v-btn>
                          </v-toolbar>
                         
                        <table class="va-table mt-5">
                          <tr>
                             <th class="border_bot"></th>
                              <th class="border_bot">Combinations</th>
                              <th class="border_bot text-right">Win Amount</th>
                            </tr>
                             <tr v-for="(item,index) in LASTTWOS.combinations" :key="index">
                               <td class="border_bot"><v-icon small color="error" @click="LASTTWOS.combinations.splice(index, 1)" >mdi-close</v-icon></td>
                              <td>
                                <input type="number" class="va-input text-center mt-2" style="width: 90px;" v-model="item.num1"/>  <input type="number" style="width: 90px;" class="va-input text-center mt-2" v-model="item.num2"/>
                              </td>
                              <td class="text-right">
                                  <input type="number" class="va-input text-center mt-2" v-model="item.amount"/> 
                              </td>
                            </tr>

                            <tr>
                              <th v-if="show_save('LASTTWO')" class="text-right pt-4" colspan="3"><v-btn color="success" small @click="SaveCombi('LASTTWO')">Save</v-btn></th>
                            </tr>
                        </table>

                    </v-col>  
                     <v-col cols="6">
                        <v-toolbar dense>
                             3D Game
                            <v-spacer/>
                            
                            <v-divider vertical inset class="ml-5 mr-2"/>
                            <v-btn fab x-small @click="add_combination('3D')"><v-icon small color="success">mdi-plus</v-icon></v-btn>
                          </v-toolbar>
                         
                        <table class="va-table mt-5">
                          <tr>
                             <th class="border_bot"></th>
                              <th class="border_bot">Combinations</th>
                              <th class="border_bot text-right">Win Amount</th>
                            </tr>
                             <tr v-for="(item,index) in THREES.combinations" :key="index">
                               <td class="border_bot"><v-icon small color="error" @click="THREES.combinations.splice(index, 1)" >mdi-close</v-icon></td>
                              <td>
                                <input type="number" class="va-input text-center mt-2" style="width: 90px;" v-model="item.num1"/>  <input type="number" style="width: 90px;" class="va-input text-center mt-2" v-model="item.num2"/> <input type="number" style="width: 90px;" class="va-input text-center mt-2" v-model="item.num3"/>
                              </td>
                              <td class="text-right">
                                  <input type="number" class="va-input text-center mt-2" v-model="item.amount"/> 
                              </td>
                            </tr>

                            <tr>
                              <th v-if="show_save('3D')" class="text-right pt-4" colspan="3"><v-btn color="success" small @click="SaveCombi('3D')">Save</v-btn></th>
                            </tr>
                        </table>
                    </v-col>
                 </v-row>  
               </v-card-text>
             </v-card>
          </v-col> -->
    </v-row>
    </v-container>
    <va-game-form :show="form" :game="game" :state="state" @DialogEvent="afEvent" />
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
        form: false,
        areaform: false,
        state: 'new',
        game: {},
        games:[],
        last_two:[],
        special_items:[],
        headers: [
            { text: 'Game', value:"username"},
            { text: 'Code'},
           //  { text: 'type'},
            { text: 'Win Amount'},
            //{ text: 'Payout(%)'},
            { text: 'Funding'},
             { text: 'Draw Times'},
             { text: 'Status'}
        ],
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
         this.getGames()
         this.GetSpeCombi()
        }
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
      LASTTWOS() {
        var items  = this.special_items.reduce((res,  item)=>{
          if(item.game == 'LASTTWO') {
            res = item
          }
          return res
        }, [])
        return items
      },
       THREES() {
        var items  = this.special_items.reduce((res,  item)=>{
          if(item.game == '3D') {
            res = item
          }
          return res
        }, [])
        return items
      }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      afEvent() {
        this.form = false
        this.getGames()
      },
      show_save(_game) {
          if(_game == 'LASTTWO') {
              return this. LASTTWOS.combinations != null?true:false
          } else if(_game == '3D') {
             return this. THREES.combinations != null?true:false
          }
          return false
      },
      formEvent(data){
          this.form = false
          if(data.action != 'close') {
              this.user = data.user
              this.Save()
          }
          console.log(data)
      },
add_combination(_game){
  var param
  if(_game == 'LASTTWO') {
    if(this. LASTTWOS.combinations != null) {
       this.LASTTWOS.combinations.push({num1:"", num2:"", limit:""})
    } else {
       param = {
        game: _game,
        combinations: [{num1:"", num2:"", limit:""}]
      }
       this.special_items.push(param)
    }
  } else {
      if(this.THREES.combinations != null) {
       this.THREES.combinations.push({num1:"", num2:"", num3:"", limit:""})
    } else {
       param = {
        game: _game,
        combinations: [{num1:"", num2:"", num3:"", limit:""}]
      }
       this.special_items.push(param)
    }
  }
},
filterFloat(_combinations, _game){
  var comb = []
  for(var i=0;i<_combinations.length; i++) {
       if(_game == 'LASTTWO') {
          comb.push({num1:this.$IsNum(_combinations[i].num1), num2: this.$IsNum(_combinations[i].num2),  amount: this.$IsNum(_combinations[i].amount) })
       } else if(_game == '3D') {
         comb.push({num1:this.$IsNum(_combinations[i].num1), num2: this.$IsNum(_combinations[i].num2), num3: this.$IsNum(_combinations[i].num3),  amount: this.$IsNum(_combinations[i].amount) })
       }
  }
  return comb
},
 SaveCombi(_game){
   var combinations
    if(_game == 'LASTTWO') {
      combinations = this.LASTTWOS.combinations
    } else {
      combinations = this.THREES.combinations
    }
          var param = {
            game: _game,
            combinations:this.filterFloat(combinations,_game)
          }
          console.log(param)
           this.$http.post("add_special_combi",param).then(response => {
           if(response.data.status) {
              this.VA_ALERT('success', response.data.message)
           }
          }).catch(e => {
            console.log(e.data)
          })
      },
      getGames(){
           this.$http.get("game/list",).then(response => {
            response.data.games != null?this.games = response.data.games:this.games =[]
            console.log(this.games)
          }).catch(e => {
            console.log(e.data)
          })
      },
       GetSpeCombi(){
           this.$http.get("get_special_combi").then(response => {
            response.data.items != null?this.special_items = response.data.items:this.special_items =[]
          }).catch(e => {
            console.log(e.data)
          })
      },
     
    }
  }
</script>